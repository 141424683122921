import React from "react";
import { Typography } from "@mui/material";

function Feed(props) {
  return (
    <div>
      {/* <Typography vairant="h1">Welcome to feed.</Typography> */}
      <h1>Welcome to feed.</h1>
      <p>
        This is where you will see what you're followers have been listening to
        recently. You can add comments and share your experiences.
      </p>

      <h4>Coming soon...</h4>
    </div>
  );
}

export default Feed;
