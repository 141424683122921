import React from "react";
import classes from "./MarqueeText.module.css";

function MarqueeText(props) {
  return (
    <>
      <div className={classes.marquee}>HEAR ME OUT</div>
    </>
  );
}

export default MarqueeText;
